'use client';

import React, { FC, JSX } from 'react';
import Script from 'next/script';
import useConfig from '@/Hooks/useConfig';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import { cookieInformationLanguageMapping } from '@/app/i18n/settingsClient';

const CookieInformation: FC = (): JSX.Element => {
  const { cookieInformationId, cookieInformationSrc, cookieInformationGcmVersion } = useConfig();
  const language = useCurrentLanguage();

  return !!cookieInformationSrc && !!cookieInformationId && !!cookieInformationGcmVersion ? (
    <Script
      id={cookieInformationId}
      src={cookieInformationSrc}
      data-culture={cookieInformationLanguageMapping[language]}
      data-gcm-version={cookieInformationGcmVersion}
      type="text/javascript"
      async
      defer
    />
  ) : (
    <></>
  );
};

export default CookieInformation;
